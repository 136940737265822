<template>
  <v-container fluid>
    <v-row no-gutters v-if="!!carriViaggio.length">
      <v-btn color="primary" class="disassocia-all" @click="deleteAllWagonFromTrip">Disassocia tutti i carri</v-btn>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <div class="wagon-ordering-panel pb-2">
          <div class="wagon-group-panel-container">
            <div class="wagon-group-panel">
              <div v-for="carroViaggio in carriViaggio" :key="carroViaggio.id" class="wagon-group-item">
                <v-card color="warning" class="wagon-card">
                  <span class="wagon-header">
                    <v-icon v-if="getCarroWorkingState(carroViaggio) === 'lavorazione'" small color="#18ca84"
                      title="Unità confermata"> mdi-pin </v-icon>
                    <v-icon v-else small color="#b8b98d" title="Unità non confermata"> mdi-pin-off </v-icon>
                    {{ getCarroLicensePlate(carroViaggio) }}
                    <v-btn v-if="!tripPlanned" x-small text style="display: inline; float: right"
                      @click="deleteWagonFromTripConvoy(carroViaggio)">
                      <v-icon v-if="carroViaggio.listUti && carroViaggio.listUti.length"> mdi-delete-forever </v-icon>
                      <v-icon v-else> mdi-delete-forever-outline </v-icon>
                    </v-btn>
                  </span>
                  <p class="wagon-details">
                    Tipo: <b>{{ getCarroType(carroViaggio) }}</b>&nbsp; Cat: <b>{{ getCategoriaCarro(carroViaggio) }}</b>
                    <br />
                    <span v-if="carroViaggio.convoglio">
                      Convoglio: <b>{{ carroViaggio.convoglio }}</b>
                      <span v-if="carroViaggio.binario">
                        <br />
                        Binario: <b>{{ carroViaggio.binario.numero }} - {{ carroViaggio.binario.parcoBinari }}</b>
                        <br />
                      </span>
                    </span>
                    ISO: <b>{{ getCarroIso(carroViaggio) }}</b> - IMO: <b>{{ getCarroImo(carroViaggio) }}</b>
                    <br />
                    <v-icon title="Peso Uti / Portata Max Carro">mdi-weight</v-icon> {{ getSumPesoUti(carroViaggio) }} /
                    {{ getCarroPortataMax(carroViaggio) }} Kg
                    <br />
                    <v-icon title="Tara Carro">mdi-train-car-centerbeam</v-icon> {{ getCarroTara(carroViaggio) }} Kg
                    <br />
                    <v-icon title="Lunghezza Carro">mdi-tape-measure</v-icon> {{ getCarroLength(carroViaggio) }} m
                    <span v-if="getCarroLengthMaxTeu(carroViaggio) !== 0">- MAX {{ getCarroLengthMaxTeu(carroViaggio) }}
                      TEU </span>
                  </p>
                  <v-icon title="danneggiato" color="error" class="damaged-alert"
                    v-if="carroViaggio.visitaCarro.danni">mdi-alert-outline</v-icon>
                  <div class="codiceConvoglio">{{ getCodiceConvoglio(carroViaggio.visitaCarro) }}</div>
                </v-card>
                <draggable :disabled="tripPlanned" group="uties" :list="carroViaggio.listUti" v-bind="dragOptions"
                  @change="onWagonCharging"
                  :class="`grey lighten-4 import-charging-zone ${carroViaggio.bloccato ? 'import-charging-zone-blocked' : ''}`">
                  <div v-for="utiCarro in carroViaggio.listUti" :key="utiCarro.id" class="uti-group-item">
                    <v-card @click="openUtiWagonDialog(carroViaggio, utiCarro)">
                      <div :class="'uti stato_' + utiCarro.stato">
                        <span class="font-weight-bold text-body-2">{{ getUtiCodeExt(utiCarro.visitaUti) }}</span>
                        <v-icon v-if="getUtiDangerousFlag(utiCarro.visitaUti)" small color="red"> mdi-alert </v-icon>
                        <br />
                        <div class="uti-text">
                          <span><v-icon title="Tipo" class="mr-1">mdi-shape</v-icon></span><b>{{
                            getUtiType(utiCarro.visitaUti) }}</b>
                          <br />
                          <span v-if="isGreaterThan29Tons(utiCarro.visitaUti)" class="red--text" title="PESO > 29 tons">
                            <span>
                              <v-icon title="Peso" class="mr-1" color="red">mdi-weight</v-icon>
                            </span>
                            <b class="red--text">{{ getUtiWeight(utiCarro.visitaUti) }}</b> Kg
                          </span>
                          <span v-else>
                            <span>
                              <v-icon title="Peso" class="mr-1">mdi-weight</v-icon>
                            </span>
                            <b>{{ getUtiWeight(utiCarro.visitaUti) }}</b> Kg
                          </span>
                          <br />
                          <span><v-icon title="Lunghezza" class="mr-1">mdi-tape-measure</v-icon></span><b>{{
                            getUtiLength(utiCarro.visitaUti) }}</b> ft
                        </div>
                      </div>
                    </v-card>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="my-2">
      <v-col cols="12" class="text-right text-overline">
        Peso tot. treno: <span class="text-body-1">{{ getPesoCarriViaggioConUti(carriViaggio) | numberRounded2WithComma }}
          Tons</span>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cerca per Tipo / Unit id" single-line hide-details
          style="max-width: 300px" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <draggable :disabled="tripPlanned" group="uties" :list="utiDaPianificare" v-bind="dragOptions"
          class="uti-group-panel-container">
          <div v-for="booking in utiDaPianificare" :key="booking.id" class="uti-group-item">
            <v-card class="uti-card">
              <div class="uti">
                <span class="font-weight-bold text-body-2">{{ getUtiCodeExt(booking.visitaUti) }}</span>
                <v-icon v-if="getUtiDangerousFlag(booking.visitaUti)" small color="red"> mdi-alert </v-icon>
                <br />
                <div class="uti-text">
                  <span><v-icon title="Tipo" class="mr-1">mdi-shape</v-icon></span>
                  <b>{{ getUtiType(booking.visitaUti) }}</b>
                  <br />
                  <span v-if="isGreaterThan29Tons(booking.visitaUti)" class="red--text" title="PESO > 29 tons">
                    <span>
                      <v-icon title="Peso" class="mr-1" color="red">mdi-weight</v-icon>
                    </span>
                    <b class="red--text">{{ getUtiWeight(booking.visitaUti) }}</b> Kg
                  </span>
                  <span v-else>
                    <span>
                      <v-icon title="Peso" class="mr-1">mdi-weight</v-icon>
                    </span>
                    <b>{{ getUtiWeight(booking.visitaUti) }}</b> Kg
                  </span>
                  <br />
                  <span><v-icon title="Lunghezza" class="mr-1">mdi-tape-measure</v-icon></span>
                  <b>{{ getUtiLength(booking.visitaUti) }}</b> ft
                </div>
              </div>
            </v-card>
          </div>
        </draggable>

        <v-expansion-panels v-model="panel" multiple class="py-2">
          <v-expansion-panel v-if="!tripPlanned && !lastUtiWagonOpIsValid" class="elevation-2">
            <v-expansion-panel-header color="red lighten-4" class="text-subtitle-1 red--text font-weight-medium">
              Errori pianificazione carico sul carro {{ lastUtiWagonOpLicensePlate }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-1">
              <v-container v-if="!lastUtiWagonOpIsValid">
                <v-row v-if="deliveryValidationError" class="my-0">
                  <v-col cols="12" class="py-0 my-0">
                    {{ deliveryValidationError }}
                  </v-col>
                </v-row>
                <v-row v-if="lengthValidationError" class="my-0">
                  <v-col cols="12" class="py-0 my-0">
                    {{ lengthValidationError }}
                  </v-col>
                </v-row>
                <v-row v-if="lengthTeuValidationError" class="my-0">
                  <v-col cols="12" class="py-0 my-0">
                    {{ lengthTeuValidationError }}
                  </v-col>
                </v-row>
                <v-row v-if="widthValidationError" class="my-0">
                  <v-col cols="12" class="py-0 my-0">
                    {{ widthValidationError }}
                  </v-col>
                </v-row>
                <v-row v-if="unitNumberValidationError" class="my-0">
                  <v-col cols="12" class="py-0 my-0">
                    {{ unitNumberValidationError }}
                  </v-col>
                </v-row>
                <v-row v-if="weightValidationError" class="my-0">
                  <v-col cols="12" class="py-0 my-0">
                    {{ weightValidationError }}
                  </v-col>
                </v-row>
                <v-row v-if="weightPerAxisValidationError" class="my-0">
                  <v-col cols="12" class="py-0 my-0">
                    {{ weightPerAxisValidationError }}
                  </v-col>
                </v-row>
                <v-row v-if="damagedWagonValidationError" class="my-0">
                  <v-col cols="12" class="py-0 my-0">
                    {{ damagedWagonValidationError }}
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels v-model="panel" multiple class="py-2">
          <v-expansion-panel v-if="!tripPlanned && lastUtiWagonOpHasWarning" class="elevation-2">
            <v-expansion-panel-header color="orange lighten-4" class="text-subtitle-1 red--text font-weight-medium">
              Segnalazioni pianificazione carico sul carro {{ lastUtiWagonOpLicensePlate }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-1">
              <v-container v-if="lastUtiWagonOpHasWarning">
                <v-row v-if="highCubeValidationWarning" class="my-0">
                  <v-col cols="12" class="py-0 my-0">
                    {{ highCubeValidationWarning }}
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="280px">
      <v-card v-if="selectedUtiCarro">
        <v-card-title>
          <span class="headline">{{ getUtiCodeExt(selectedUtiCarro.visitaUti) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-checkbox v-if="selectedCarro.stadioLavorazione && selectedCarro.stadioLavorazione.toLowerCase() === 'lavorazione'" label="Tassativo carro"
                  v-model="selectedUtiCarro.tassativoCarro" />
              </v-col>
              <v-col cols="12">
                <v-checkbox label="Tassativo tipo carro" v-model="selectedUtiCarro.tassativoTipoCarro" />
              </v-col>
              <v-col cols="12">
                <v-checkbox label="Porta a porta" v-model="selectedUtiCarro.portaAPorta" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Chiudi</v-btn>
          <v-btn @click="saveUtiWagonSettings">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import tripVisualizationMixins from "../mixins/tripVisualizationMixins";

export default {
  name: "WagonImportPlanning",
  components: {
    draggable,
  },
  props: {
    listCarri: {
      type: Array,
      default: () => [],
    },
    bookingPianificabiliList: {
      type: Array,
      default: () => [],
    },
    tripPlanned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      selectedCarro: null,
      selectedUtiCarro: null,
      search: null,
      panel: [0],
      moving: false
    };
  },
  created() {
     this.$eventBus.$on("joinUtiListWagon", (chargingValidationResult) => (this.chargingValidationResult = chargingValidationResult));
  },
  mounted() {
    this.setConvoyData(this.listCarri);
  },
  mixins: [tripVisualizationMixins],
  computed: {
    utiDaPianificare() {
      return this._.filter(
        this.bookingPianificabiliList,
        (b) =>
          (!this.search ||
            this.getUtiType(b.visitaUti)?.toLowerCase().includes(this.search?.toLowerCase()) ||
            this.getUtiCodeExt(b.visitaUti)?.toLowerCase().includes(this.search?.toLowerCase())) &&
          !this._.find(this.listCarri, (c) => this._.find(c.listUti, (u) => u.visitaUtiId === b.visitaUtiId))
      );
    },
    carriViaggio() {
      return this._.orderBy(this.listCarri, [(cv) => cv.posizione]);
    },
    lastUtiWagonOpIsValid() {
      return this.chargingValidationResult ? this.chargingValidationResult.valid : true;
    },
    lastUtiWagonOpHasWarning() {
      return this.chargingValidationResult ? this.chargingValidationResult.warning : false;
    },
    lastUtiWagonOpLicensePlate() {
      return this.chargingValidationResult ? this.chargingValidationResult.targa : null;
    },
    deliveryValidationError() {
      if (this.chargingValidationResult) {
        if (this.chargingValidationResult.assenzaDelivery) {
          return "Delivery non presente";
        }
        if (this.chargingValidationResult.deliveryScaduto) {
          return "Data della partenza successiva a quella del delivery";
        }
        return "";
      }
    },
    lengthValidationError() {
      const detectedLength = this.chargingValidationResult ? this.chargingValidationResult.lunghezzaRilevata : 0;
      const maxLength = this.chargingValidationResult ? this.chargingValidationResult.lunghezza : 0;
      if (detectedLength > maxLength) return `Lunghezza rilevata (${detectedLength} m) superiore a quella consentita (${maxLength} m)`;
      return "";
    },
    lengthTeuValidationError() {
      const detectedLength = this.chargingValidationResult ? this.chargingValidationResult.lunghezzaTeuRilevata : 0;
      const maxLength = this.chargingValidationResult ? this.chargingValidationResult.lunghezzaMaxTeu : 0;
      if (detectedLength > maxLength) return `Lunghezza TEU rilevata (${detectedLength} TEU) superiore a quella consentita (${maxLength} TEU)`;
      return "";
    },
    widthValidationError() {
      const detectedWidth = this.chargingValidationResult ? this.chargingValidationResult.larghezzaRilevata : 0;
      const maxWidth = this.chargingValidationResult ? this.chargingValidationResult.larghezza : 0;
      if (detectedWidth > maxWidth) return `Larghezza rilevata (${detectedWidth} m) superiore a quella consentita (${maxWidth} m)`;
      return "";
    },
    unitNumberValidationError() {
      const detectedUnitNumber = this.chargingValidationResult ? this.chargingValidationResult.numeroUnitRilevate : 0;
      const maxUnitNumber = this.chargingValidationResult ? this.chargingValidationResult.numeroUnit : 0;
      if (detectedUnitNumber > maxUnitNumber) return `Numero Unità caricate superiore al massimo consentito (${maxUnitNumber})`;
      return "";
    },
    weightValidationError() {
      const detectedTotalWeight = this.chargingValidationResult ? this.chargingValidationResult.pesoTotaleRilevato : 0;
      const maxLoadCapacity = this.chargingValidationResult ? this.chargingValidationResult.portataMax : 0;
      if (detectedTotalWeight > maxLoadCapacity) return `Peso totale rilevato (${detectedTotalWeight}) superiore alla portata massima (${maxLoadCapacity})`;
      return "";
    },
    weightPerAxisValidationError() {
      const detectedTotalWeightPerAxis = this.chargingValidationResult ? this.chargingValidationResult.pesoTotalePerAsseRilevato : 0;
      const maxLoadCapacityPerAxis = this.chargingValidationResult ? this.chargingValidationResult.portataMaxPerAsse : 0;
      if (detectedTotalWeightPerAxis > maxLoadCapacityPerAxis)
        return `Peso totale per asse rilevato (${detectedTotalWeightPerAxis}) superiore alla portata massima per asse (${maxLoadCapacityPerAxis})`;
      return "";
    },
    highCubeValidationWarning() {
      const isHighCube = this?.chargingValidationResult?.hasHighCube || false;
      const isRibassato = this?.chargingValidationResult?.ribassato || false;
      if (isHighCube && !isRibassato) {
        return `ATTENZIONE! Il container è High Cube ed è stato pianificato su un carro non ribassato`;
      }
      return "";
    },
    damagedWagonValidationError() {
      return this?.chargingValidationResult?.danneggiato ?
        'Il carro è danneggiato e non puo ricevere pianificazione di container' : '';
    },
  },
  methods: {
    checkMove(evt) {
      alert(JSON.stringify(evt));
    },
    async onWagonCharging(evt) {
      console.log("onWagonCharging", evt);
      try {
        if (evt.added) {
          if (evt.added.element.carroViaggioId) {
            const utiCarro = evt.added.element;
            this.moving = true;
            await this.moveUtiBetweenWagons(utiCarro, this.listCarri);
          } else {
            const booking = evt.added.element;
            if (booking.codice || booking.codice === null) {
              await this.joinBookingWagon(booking, this.listCarri);
            } else {
              //const utiCarro = evt.added.element;
              //console.log("***ELSE booking.codice****utiCarro*******: ",evt.added.element);
              //await this.moveUtiBetweenWagons(utiCarro);
              this.showError("Operazione non consentita");
            }
          }
        } else if (evt.removed) {
          if (!this.moving) {
            const utiCarro = evt.removed.element;
            if (!this.checkForOntheOtherWagon(utiCarro)) {
              await this.unjoinUtiFromWagon(utiCarro);
            }
          } else {
            this.moving = false;
          }
        } else if (evt.moved) {
          //const utiCarro = evt.moved.element;
          //await this.moveUtiBetweenWagons(utiCarro);
          this.showError("Operazione non consentita");
        }
        this.$eventBus.$emit("planningChange");
      } catch (e) {
        console.log(e);
      }
    },
    async deleteWagonFromTripConvoy(carroViaggio) {
      try {
        if (carroViaggio) {
          await this.$api.trainData.cancellaCarroViaggio(carroViaggio);
          this.chargingValidationResult = null;
          this.$eventBus.$emit("planningChange");
        }
      } catch (e) {
        this.showError("fallita cancellazione carro da viaggio");
        throw e;
      }
    },
    async moveUtiBetweenWagons(utiCarro, listCarri) {
      try {
        const carroViaggio = listCarri.find(cv => cv.listUti.find(uc => uc.visitaUti.id === utiCarro.visitaUtiId));
        this.chargingValidationResult = await this.$api.trainData.moveUtiToWagon(utiCarro.visitaUtiId, carroViaggio.id);
      } catch (e) {
        this.showError("aggiornamento pianificazione non andato a buon fine");
        throw e;
      }
    },
    checkForOntheOtherWagon(utiCarro) {
      let result = false;
      this.listCarri.forEach((cv) => {
        if (this._.find(cv.listUti, (uc) => uc === utiCarro)) {
          result = true;
        }
      });
      return result;
    },
    openUtiWagonDialog(carro, utiCarro) {
      this.selectedCarro = carro;
      this.selectedUtiCarro = utiCarro;
      this.dialog = true;
    },
    async saveUtiWagonSettings() {
      try {
        await this.$api.trainData.salvaUtiCarroSettings(this.selectedUtiCarro);
        this.dialog = false;
        this.selectedUtiCarro = null;
        this.selectedCarro = null;
        this.$eventBus.$emit("planningChange");
      } catch (e) {
        this.showError("aggiornamento settings uti-carro non andato a buon fine");
        throw e;
      }
    },
    setConvoyData(listCarri) {
      if (this.listCarri?.length) {
        listCarri.forEach((cv) => {
          if (cv?.visitaCarro?.carroConvoglioList?.length) {
            const carroConvoglio = this._.find(cv.visitaCarro.carroConvoglioList, (cc) => cc.convoglio.stato.toLowerCase() === "interminal");
            if (carroConvoglio) {
              const convoglio = carroConvoglio.convoglio;
              if (convoglio) {
                cv.convoglio = convoglio.nome;
                if (convoglio.binario) {
                  cv.binario = { numero: convoglio.binario.numero };
                  if (convoglio.binario.parcoBinari) {
                    cv.binario.parcoBinari = convoglio.binario.codiceParcoBinari;
                  }
                }
              }
            }
          }
        });
      }
    },
    getCodiceConvoglio(carroViaggio) {
      if (carroViaggio.nomeConvoglio) return carroViaggio.nomeConvoglio.split("-")[0];
    },
    async deleteAllWagonFromTrip() {
      if (confirm("Confermare la disassociazione di tutti i carri da questo viaggio in partenza?")) {
        await this.$api.trainData.cancellaTuttiCarroViaggio(this.tripId);
        this.chargingValidationResult = null;
        this.$eventBus.$emit("planningChange");
      }
    }
  },
  watch: {
    listCarri(newListCarri) {
      this.setConvoyData(newListCarri);
    },
  },
};
</script>

<style scoped>
.wagon-group-panel-container {
  padding-top: 5px;
  max-height: 400px;
  background-color: #ffffff;
}
.wagon-group-panel-container > div {
  display: table-cell;
  height: 30px;
}
.wagon-ordering-panel {
  overflow-x: auto;
}
.wagon-ordering-panel-container > div {
  display: table-cell;
  height: 50px;
}
.wagon-ordering-panel-container > div > div {
  padding-left: 5px;
  width: 155px;
}
.wagon-group-panel {
  min-height: 50px;
  display: block;
}
.wagon-group-item {
  cursor: move;
  padding-right: 5px;
  display: table-cell;
}
.wagon-group-item > div {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 3px;
  width: 165px !important;
  height: 160px;
  overflow: auto;
}
.uti-group-panel-container {
  height: auto;
  width: 100%;
  padding-bottom: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  overflow: auto;
}
.uti-validation-result-container {
  height: 100px;
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 5px 0px 5px 0px;
  overflow-y: scroll;
}
.uti-validation-result-container ul {
  list-style: none;
  color: #ef6262;
}
.uti-group-item {
  cursor: move;
  padding-left: 5px;
  float: left;
  padding-top: 5px;
}
.uti-group-item > div {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 3px;
  width: 150px !important;
  height: 72px;
  border-style: solid;
  border-width: 2px !important;
  border-color: lightgrey;
}
.uti-group-item > div > .uti span {
  float: left;
}
.uti-group-item > div > .uti .uti-text {
  clear: both;
  text-align: left;
  font-size: 0.6rem;
}
.wagon-uti-group-item {
  cursor: move;
  padding-top: 5px;
}
.wagon-uti-group-item > div {
  margin-top: 1px;
  padding: 2px;
  width: 100%;
  height: 72px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 2px !important;
  border-color: lightgrey;
}
.wagon-uti-group-item > div > .uti {
  display: flex;
  justify-content: center;
  align-items: center;
}
.import-charging-zone {
  margin-top: 3px !important;
  height: 195px !important;
}
.import-charging-zone-blocked {
  background-image: repeating-linear-gradient(-45deg, #ffffff, #ffffff 10px, rgb(196, 196, 192) 10px, rgb(196, 196, 192) 20px) !important;
}
.wagon-header,
.wagon-title {
  font-size: 0.7rem !important;
  font-weight: bold !important;
}
.wagon-details,
.wagon-details i {
  font-size: 0.7rem !important;
}
.uti {
  height: 100%;
  width: 100%;
  font-size: 0.6rem;
}
.uti i {
  font-size: 0.8rem;
  font-weight: bold;
  float: right;
}
.uti span {
  font-size: 0.6rem;
}
.uti-text {
  text-align: center;
}
.stato_CARICATO {
  background-color: #f0f0f0;
}
.codiceConvoglio {
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: #ffb74d;
}
.wagon-damage {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  flex-direction: row;
}
.damaged-alert {
  position: absolute;
  left: 130px;
  top: 85px;
}
.disassocia-all {
  margin-bottom: 15px;
}
</style>
