<template>
  <div>
    <v-tabs>
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="i in ['utiList']" :key="i" :href="`#tab-${i}`">
        {{ tLabel(i) }}
      </v-tab>

      <v-tab-item v-for="i in ['utiList']" :key="i" :value="'tab-' + i">
        <v-card-title>
          <v-row no-gutters :justify="'space-between'">
            <v-col cols="3">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Cerca per Tipo / Unit id" single-line hide-details style="max-width: 300px" />
            </v-col>

            <v-col :cols="selectedBookingsToRemoveFromWagon || selectedBookingsToJoinWithWagon ? 3 : 9">
              <v-btn color="primary" v-if="hasCosmosConnector() && !readonly" class="ma-2" @click="doRequestCosmosLoadingList()" :title="tLabel('Request Loading List')">
                <v-icon>mdi-update</v-icon>
              </v-btn>
              <v-btn color="primary" v-if="hasCosmosConnector() && !readonly" class="ma-2" @click="doRequestCosmosImportUtiState()" :title="tLabel('Request Stato Uti')">
                <v-icon>mdi-lock-reset</v-icon>
              </v-btn>
              <v-btn color="primary" v-if="hasCosmosConnector() && !readonly" class="ma-2" @click="doRequestCosmosImportRailRegistration()" :title="tLabel('Request Rail registration')">
                <v-icon>mdi-file-document-edit-outline</v-icon>
              </v-btn>
              <v-btn color="primary" v-if="hasCosmosConnector() && !readonly" class="ma-2" @click="doRequestCosmosImportHandlingInExe()" :title="tLabel('Request Handling in Exe')">
                <v-icon>mdi-radio-handheld</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="6" v-if="selectedBookingsToRemoveFromWagon || selectedBookingsToJoinWithWagon">
              <!-- <div v-if="i === 'riserveUtiList'">
                <v-btn 
                  v-if="selectedBookingsToUnplanEnabled"
                  small
                  title="Rimuovi (selezionati) dai pianificabili"
                  color="#ef6262"
                  @click="moveSelectedBookingsToUnplanned()"
                >
                    <v-icon small>mdi-upload-off</v-icon>
                </v-btn>
                <span v-if="selectedBookingsToUnplanEnabled && selectedBookingsToPlanEnabled">|</span>
                <v-btn 
                  v-if="selectedBookingsToPlanEnabled"
                  small
                  title="Sposta (selezionati) in pianificazione"
                  color="#20d077"
                  @click="moveSelectedBookingsToPlanning()"
                >
                    <v-icon small>mdi-upload</v-icon>
                </v-btn>           
              </div> -->

              <div class="gen-buttons">
                <div v-if="selectedBookingsToRemoveFromWagon">
                  <v-btn small title="Rimuovi (selezionati) da carro" color="warning" @click="removeSelectedFromWagon()">
                    <v-icon small>mdi-upload</v-icon>
                  </v-btn>
                </div>
                <div v-if="selectedBookingsToJoinWithWagon">
                  <div>
                    <v-combobox v-model="selectedWagonToCharge" :items="listCarri" label="Carro da caricare" outlined dense required style="max-width: 300px">
                      <template v-slot:item="{ item }">
                        {{ `${item.posizione} - ${getCarroType(item)} - ${getCarroLicensePlate(item)}` }}
                      </template>
                      <template v-slot:selection="{ item }"> {{ item.posizione }} - {{ getCarroLicensePlate(item) || getCarroType(item) }} </template>
                    </v-combobox>
                  </div>
                  <div>
                    <v-btn small title="Carica (selezionati) sul carro:" color="success" @click="joinSelectedWagon()">
                      <v-icon small>mdi-upload</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div>
                  <v-btn :disabled="checkSelectedUtiList" small title="Rimuovi (selezionati) dai pianificabili" color="error" @click="removeSelectedFromViaggio()">
                    <v-icon small>mdi-upload-off</v-icon>
                  </v-btn>
                </div>
                <!-- <span>|</span>
                <v-btn 
                  small
                  title="Rimuovi (selezionati) dai pianificabili"
                  color="error"
                  @click="moveSelectedBookingsToUnplanned()"
                >
                    <v-icon small>mdi-upload-off</v-icon>
                </v-btn> -->
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          dense
          v-model="selectedUtiList[i]"
          :headers="headers[i]"
          :items="bookingListMap[i]"
          :items-per-page="10000"
          :search="search"
          :show-select="!tripPlanned"
          hide-default-footer
          :custom-filter="customFilter"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td v-if="!tripPlanned">
                  <div class="trip-table-detail">
                    <v-checkbox v-model="selectedUtiList[i]" :value="item" style="margin: 0px; padding: 0px" />
                  </div>
                </td>
                <td>
                  <div class="trip-table-detail">
                    <v-icon v-if="getUtiDangerousFlag(item.visitaUti)" color="error" small> mdi-alert </v-icon>
                    {{ getUtiType(item.visitaUti) }}
                  </div>
                </td>
                <td>
                  <div class="trip-table-detail">
                   {{ getUtiCode(item.visitaUti) }}
                  </div>
                </td>
                <td>
                  <div class="trip-table-detail">
                   {{ item.visitaUti.company }}
                  </div>
                </td>
                <td>
                  <div class="trip-table-detail">
                    {{ getUtiWeight(item.visitaUti) }}
                  </div>
                </td>
                <td>
                  <div class="trip-table-detail">
                    {{ getUtiTareWeight(item.visitaUti) }}
                  </div>
                </td>
                <td>
                  <div class="trip-table-detail">
                    {{ getUtiLength(item.visitaUti) }}
                  </div>
                </td>
                <td v-if="i === 'utiList'">
                  {{ getUtiStatus(item.visitaUti, item.viaggioTrenoId) }}
                </td>
                <td>
                  <span v-if="item.visitaUti.fot">
                    {{ item.visitaUti.fot }}
                  </span>
                  <span v-if="item.visitaUti.scadenzaFot"> &nbsp;scad. {{ item.visitaUti.scadenzaFot | formatDate }} </span>
                </td>
                <td>
                  <v-icon v-if="item.visitaUti && item.visitaUti.blocco === true" x-small color="error">mdi-radiobox-marked</v-icon>
                  <v-icon v-else x-small color="success">mdi-radiobox-marked</v-icon>
                </td>
                <td>
                  <span v-if="item.visitaUti && item.visitaUti.fullEmpty">{{ item.visitaUti.fullEmpty }}</span>
                </td>
                <td v-if="i === 'utiList'">
                  <div class="trip-table-detail">
                    <v-icon v-if="getUtiCarroTassativoTipoCarro(item)" x-small color="success">mdi-radiobox-marked</v-icon>
                    <v-icon v-else x-small color="warning">mdi-radiobox-blank</v-icon> /
                    <v-icon v-if="getUtiCarroTassativoCarro(item)" x-small color="success">mdi-radiobox-marked</v-icon>
                    <v-icon v-else x-small color="warning">mdi-radiobox-blank</v-icon> /
                    <v-icon v-if="getUtiCarroPortaAPorta(item)" x-small color="success">mdi-radiobox-marked</v-icon>
                    <v-icon v-else x-small color="warning">mdi-radiobox-blank</v-icon>
                  </div>
                </td>
                <td>
                  {{ item.carroViaggio ? getCarroType(item.carroViaggio) : "" }}
                </td>
                <td>
                  {{ item.carroViaggio ? getCarroLicensePlate(item.carroViaggio) : "" }}
                </td>
                <td>
                  <div class="trip-table-detail">
                    <div v-if="i === 'utiList' && item.carroViaggio && !tripPlanned">
                      <v-btn small title="Rimuovi da carro" color="warning" @click="removeFromWagon(item)">
                        <v-icon small>mdi-upload</v-icon>
                      </v-btn>
                    </div>
                    <!-- <div v-if="i === 'utiList' && !tripPlanned">
                          <v-btn                          
                            small
                            title="Rimuovi dai pianificabili"
                            color="error"
                            @click="moveBookingToUnplanned(item)"
                          >
                              <v-icon small>mdi-upload-off</v-icon>
                          </v-btn>
                        </div> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import tripVisualizationMixins from "../mixins/tripVisualizationMixins";

export default {
  name: "ImportUtiList",
  components: {},

  props: {
    tripId: {
      type: String,
      required: true,
    },
    tripPlanned: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [tripVisualizationMixins],
  data() {
    return {
      bookings: [],
      listCarri: [],
      codiceEsterno: null,
      search: null,
      selectedWagonToCharge: null,
      selectedUtiList: {
        utiList: [],
        riserveUtiList: [],
      },
      headers: {
        utiList: [
          { text: "Tipo", value: "tipo", class: "header text-uppercase" },
          { text: "Unit ID", value: "nome", class: "header text-uppercase" },
          { text: "Company", value: "", class: "header text-uppercase" },
          { text: "Peso (Kg)", value: "peso", class: "header text-uppercase" },
          { text: "Tara (Kg)", value: "tara", class: "header text-uppercase" },
          { text: "Lunghezza", value: "lunghezza", class: "header text-uppercase" },
          { text: "Stato", value: "stato", class: "header text-uppercase" },
          { text: "Delivery", value: "fot", class: "header text-uppercase" },
          { text: "Blocco", value: "blocco", class: "header text-uppercase" },
          { text: "Full/Empty", value: "fullEmpty", class: "header text-uppercase" },
          { text: "T. tipo carro / T. carro / p. a p.", value: "carro", class: "header text-uppercase" },
          { text: "Tipo carro", value: "tipoCarro", class: "header text-uppercase" },
          { text: "Carro", value: "carro", class: "header text-uppercase" },
          { text: "Azioni", value: "actionImport", sortable: false, filterable: false, class: "header text-uppercase" },
        ],
        riserveUtiList: [
          { text: "Tipo", value: "tipo", class: "header text-uppercase" },
          { text: "Unit ID", value: "nome", class: "header text-uppercase" },
          { text: "Company", value: "", class: "header text-uppercase" },
          { text: "Peso", value: "peso", class: "header text-uppercase" },
          { text: "Tara", value: "tara", class: "header text-uppercase" },
          { text: "Lunghezza", value: "lunghezza", class: "header text-uppercase" },
          { text: "Delivery", value: "fot", class: "header text-uppercase" },
          { text: "Blocco", value: "blocco", class: "header text-uppercase" },
          { text: "Full/Empty", value: "fullEmpty", class: "header text-uppercase" },
          { text: "Azioni", value: "actionAvailable", sortable: false, filterable: false, class: "header text-uppercase" },
        ],
      },
      readonly: false
    };
  },
  created() {
    this.$eventBus.$on("removingPlannedUti", (utiToUnplan) => {
      if (!this._.find(this.bookings, (uti) => uti === utiToUnplan)) {
        this.bookings.push(utiToUnplan);
      }
    });

    this.$eventBus.$on("loadingListReload", async () => {
      await this.fetchData();
    });

    this.$eventBus.$on("railRegistrationReload", async () => {
      await this.fetchData();
    });

    this.$eventBus.$on("handlingInExeReload", async () => {
      await this.fetchData();
    });
    this.$eventBus.$on("utiStateReload", async () => {
      await this.fetchData();
    });
    this.$eventBus.$on("planningChange", async () => {
      await this.fetchData();
    });
  },
  async mounted() {
    await this.fetchData();
    const loggedUser = this.$store.getters['authentication/loggedUser'];
    this.readonly = loggedUser?.roles?.includes("EXT_TOS_USER");
  },
  computed: {
    bookingListMap() {
      //Nota: riserveUtiList sarà sempre vuoto, perchè tutti i bookings sono del viaggio!
      //TODO rimuovere riserveUtiList (o gestirlo..), semplificare!
      const map = this._.transform(
        this.bookings,
        (result, b) => {
          if (!result.riserveUtiList) result.riserveUtiList = [];
          if (!result.utiList) result.utiList = [];
          if (b.viaggioTrenoId === +this.tripId) {
            b.carroViaggio = this._.find(this.listCarri, (c) => this._.find(c.listUti, (u) => u.visitaUtiId === b.visitaUtiId));
            result.utiList.push(b);
          } else if (b.visitaUti) {
            result.riserveUtiList.push(b);
          }
          return result;
        },
        {}
      );
      return map;
    },
    plannedOperationsEnabled() {
      return !this.tripPlanned && this.selectedUtiList.import.length;
    },
    selectedBookingsToRemoveFromWagon() {
      return !this.tripPlanned && this._.find(this.selectedUtiList.utiList, (b) => b.viaggioTrenoId === +this.tripId && b.carroViaggio);
    },
    selectedBookingsToJoinWithWagon() {
      return !this.tripPlanned && this._.find(this.selectedUtiList.utiList, (b) => b.viaggioTrenoId === +this.tripId && !b.carroViaggio);
    },
    checkSelectedUtiList() {
      console.log("selectedUtiList", this.selectedUtiList);
      return this._.find(this.selectedUtiList.utiList, (b) => b.carroViaggio);
    },
  },
  methods: {
    async fetchData() {
      try {
        const viaggio = await this.$api.trainData.getViaggioById(+this.tripId);
        this.bookings = viaggio.listBooking;
        this.listCarri = viaggio.listCarri;
        this.codiceEsterno = viaggio.codiceEsterno;
      } catch (e) {
        console.log(e);
      }
    },
    customFilter(value, search, item) {
      return (
        item &&
        search &&
        (this.getUtiType(item.visitaUti).toLowerCase().includes(search.toLowerCase()) || this.getUtiCode(item.visitaUti).toLowerCase().includes(search.toLowerCase()))
      );
    },
    async removeFromWagon(booking) {
      try {
        if (booking.carroViaggio) {
          const utiCarro = this._.find(booking.carroViaggio.listUti, (uc) => booking.visitaUtiId === uc.visitaUtiId);
          await this.unjoinUtiFromWagon(utiCarro);
        }
      } catch (e) {
        this.showError("aggiornamento pianificazione non andato a buon fine");
        console.log(e);
      } finally {
        this.$eventBus.$emit("planningChange");
      }
    },
    async removeFromViaggio(booking) {
      try {
        if (!booking.carroViaggio) {
          await this.unjointUtiFromViaggio(booking.id);
        }
        console.log("booking->", booking);
      } catch (e) {
        this.showError("can't remove booking from loading list");
        console.log(e);
      } finally {
        this.$eventBus.$emit("planningChange");
      }
    },
    async removeSelectedFromWagon() {
      try {
        await Promise.all(
          this.selectedUtiList.utiList.map(async (b) => {
            if (b.carroViaggio) {
              await this.removeFromWagon(b);
            }
          })
        );
      } catch (e) {
        this.showError("aggiornamento pianificazione non andato a buon fine");
        console.log(e);
      } finally {
        this.selectedUtiList.utiList = [];
        this.$eventBus.$emit("planningChange");
      }
    },
    async removeSelectedFromViaggio() {
      try {
        await Promise.all(
          this.selectedUtiList.utiList.map(async (b) => {
            if (!b.carroViaggio) {
              await this.removeFromViaggio(b);
            }
          })
        );
      } catch (e) {
        this.showError("error on moving to unplanned");
        console.log(e);
      } finally {
        this.selectedUtiList.utiList = [];
        this.$eventBus.$emit("planningChange");
      }
    },
    async joinSelectedWagon() {
      try {
        if (!this.selectedWagonToCharge) {
          this.showError("Selezionare un carro");
          return;
        }
        // await Promise.all(this.selectedUtiList.utiList.map(async (b) => {
        //   this.chargingValidationResult = await this.$api.trainData.associaUtiCarro(b, this.selectedWagonToCharge);
        //   this.$eventBus.$emit('joinUtiListWagon', this.chargingValidationResult);
        // }));
        for (let i = 0; i < this.selectedUtiList.utiList.length; i++) {
          let b = this.selectedUtiList.utiList[i];
          this.chargingValidationResult = await this.$api.trainData.associaUtiCarro(b, this.selectedWagonToCharge);
          this.$eventBus.$emit("joinUtiListWagon", this.chargingValidationResult);
        }
      } catch (e) {
        this.showError("aggiornamento pianificazione non andato a buon fine");
        console.log(e);
      } finally {
        this.selectedWagonToCharge = null;
        this.selectedUtiList.utiList = [];
        this.$eventBus.$emit("planningChange");
      }
    },
    async doRequestCosmosLoadingList() {
      await this.$api.trainData.doRequestCosmosLoadingList(this.codiceEsterno);
      const msg = "Invio richiesta aggiornamento Loading List";
      this.showSuccess(msg);
      await new Promise((r) => setTimeout(r, 5000));
      this.$eventBus.$emit("loadingListReload");
    },
    async doRequestCosmosImportRailRegistration() {
      await this.$api.trainData.doRequestCosmosImportRailRegistration(this.codiceEsterno);
      const msg = "Invio richiesta aggiornamento Rail Registration";
      this.showSuccess(msg);
      await new Promise((r) => setTimeout(r, 5000));
      this.$eventBus.$emit("railRegistrationReload");
    },

    async doRequestCosmosImportHandlingInExe() {
      await this.$api.trainData.doRequestCosmosImportHandlingInExe(this.codiceEsterno);
      const msg = "Invio richiesta aggiornamento Handling in Exe";
      this.showSuccess(msg);
      await new Promise((r) => setTimeout(r, 5000));
      this.$eventBus.$emit("handlingInExeReload");
    },

    async doRequestCosmosImportUtiState() {
      await this.$api.trainData.doRequestCosmosImportUtiState(this.codiceEsterno);
      const msg = "Invio richiesta aggiornamento Stato Uti";
      this.showSuccess(msg);
      await new Promise((r) => setTimeout(r, 10000));
      this.$eventBus.$emit("utiStateReload");
    },
  },
  watch: {
    bookings() {
      this.selectedUtiList = {
        utiList: [],
        riserveUtiList: [],
      };
    },
  },
};
</script>

<style scoped>
.trip-table-detail {
  width: 100%;
  text-align: center;
  height: auto;
}
.trip-table-detail > div {
  float: left;
}
.gen-buttons div {
  float: right;
  margin-left: 3px;
}
</style>
